import React, {useContext, useEffect} from 'react'
import { UserContext, handleLogout } from '@parallelpublicworks/entitysync'
import { CircularProgress } from '../components/library'
import { navigate } from 'gatsby-link'

function Logout() {
  const userContext = useContext(UserContext)
  useEffect(() => {
    console.log('loggin out');
    handleLogout(userContext)
    if(typeof window !== 'undefined'){
      window.location.replace('/login')
    }
    navigate('/login')
  }, [userContext])

  return (
    <div>
      <CircularProgress />
    </div>
  )
}

export default Logout
